import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { useSpring, animated as a } from 'react-spring';

const showBox = keyframes`
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
`;

const showContent = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2%);
  }
  100% {
    opacity: 100%;
    transform: translateX(0);
  }
`;

const StyleSticky = styled.div`
  width: 100%;
  height: auto;
  margin-top: 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.body};
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 10px;
    background-color: white;
    position: absolute;
    bottom: 0;
  }
  ${({ theme }) => theme.media.desktop} {
    height: auto;
    margin-top: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.body};
    position: relative;
    &::after {
      content: '';
      height: 10px;
      background-color: white;
      position: absolute;
      bottom: 0;
    }
  }
`;

const StyleWrpapper = styled.div`
  width: 95%;
  max-width: 1100px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 88%;
    flex-direction: row;
    border-bottom: 2px solid ${({ theme }) => theme.blue};
    align-items: flex-start;
`;

const Stickys = styled.summary`
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.blue};
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.3);
  font-size: ${({ theme }) => theme.font.size.xxs};
  border-radius: 15px;
  text-align: center;
  color: ${({ theme }) => theme.blue};
  &:hover .see {
    display: block;
  }
  &[open] {
    border-radius: 15px 15px 0 0; }
  ${({ theme }) => theme.media.desktop} {
    min-width: 200px;
    justify-content: center;
    margin-bottom: 15px;
    transition: hover .3s ease-in;
    font-size: ${({ theme }) => theme.font.size.xs};
  }
  &:hover .see {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
  }
}
`;

const Details = styled.details`
  width: 90%;
  height: auto;
  margin-top: 30px;
  position: relative;
  &:nth-child(4) {
    margin-bottom: 20px;
  }
  div {
    transform: scaleY(0);
    transform-origin: 0 0;
    animation: 0.5s ease-in-out 1 forwards ${showBox};
    font-size: 18px;
    line-height: 1.5;
    p {
      opacity: 0;
      animation: 0.3s 0.4s ease-in 1 forwards ${showContent};
    }
    a {
      opacity: 0;
      animation: 0.3s 0.4s ease-in 1 forwards ${showContent};
    }
  }
  summary {
    list-style: none;
    cursor: pointer;
  }
  summary::before {
    content: '>';
    position: relative;
    font-size: 20px;
    margin-right: 10px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
  }
  &[open] {
    summary {
      padding-bottom: 0;
      margin-bottom: 0;
      border-radius: 15px 15px 0 0;
    }
    summary::before {
      transform: rotate(-90deg);
    }
  }
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      summary::-webkit-details-marker {
        display: none;
      }
      summary:focus {
        outline: 3px solid #f9e852;
      }
    }
  }
  ${({ theme }) => theme.media.tablet} {
    height: auto;
    display: flex;
    flex: 45%;
    margin: 17px 0;
    position: relative;
    &:first-child {
      margin-right: 5%;
    }
    &:nth-child(3) {
      margin-right: 5%;
    }
  }
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 15px;
    width: 25%;
    margin-right: 2% !important;
    position: relative;
    flex: 1;

    &:last-child {
      margin-right: 0;
      margin-bottom: 15px;
    }
    &:nth-child(3) {
      &:hover .see {
        height: 95px;
        padding: 2px;
        top: -0px;
      }
    ;
    }
  ;
  }
;
`;

const StyledDes = styled.div`
  width: 100%;
  min-height: 70px;
  background-color: ${({ theme }) => theme.blue};
  font-size: ${({ theme }) => theme.font.size.xs};
  color: white;
  padding: 10px;
  text-align: center;
  top: -2px;
  left: 0;
  border-radius: 0 0 15px 15px;
  border: 2px solid ${({ theme }) => theme.blue};
  z-index: 9999;
  transition: display .3s ease-in;
  word-break: keep-all;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
  }
`;

const Links = styled.a`
  width: auto;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid white;
  padding: 5px;
  color: white;
  transition: all 0.2s ease-in-out;
  &:hover {
    font-weight: 700;
    color: white;
    text-decoration: none;
    transform: scale(1.03);
  }
`;


const Sticky = () => {
  const data = useStaticQuery(graphql`
      query{
          allDatoCmsSticky(sort: {fields: sort}) {
              nodes {
                  id
                  sort
                  title
                  description
                  url
                  titleurl
              }
          }
      }
  `);

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      return
    }
    const All_Details = document.querySelectorAll('details');


    All_Details.forEach(deet => {
      deet.addEventListener('toggle', toggleOpenOneOnly);
    });
    function toggleOpenOneOnly() {
      if (this.open) {
        All_Details.forEach(deet => {
          if (deet !== this && deet.open) deet.open = false;
        });
      }
    }
  }, [])


  const [greetingStatus, displayGreeting] = useState(false);

  const contentProps = useSpring({
    opacity: greetingStatus ? 1 : 0,
    scaleY: greetingStatus ? 1 : 0
  });


  const toogleStatus = async (e) => {

    if (greetingStatus === true) {
      /*    await Change_Details();*/
      await displayGreeting(true);

    } else {
      displayGreeting(true);
    }

  };
  return (

    <StyleSticky>
      <StyleWrpapper>
        {data.allDatoCmsSticky.nodes.map((Stick) => (
          <Details key={Stick.id}>

            <Stickys  onClick={toogleStatus}><h3>{Stick.title}</h3></Stickys>
            <>{greetingStatus ? (
              <a.div style={contentProps}>

                <StyledDes>{Stick.description ? (<><p>{Stick.description}</p></>) : ''}
                  {Stick.url ? (<Links className='link' href={Stick.url}>{Stick.titleurl}</Links>) : ''}

                </StyledDes>
              </a.div>) : null}</>
          </Details>
        ))}
      </StyleWrpapper>

    </StyleSticky>
  );


};
export default Sticky;